import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: 742px) {
    flex-wrap: wrap;
  }
`;

export const Label = styled.label`
  width: 100%;
  margin: 0 1rem;
  font-size: 75%;
`;

export const TextInput = styled.input`
  display: block;
  outline: none;
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 3px;
`;

export const TextArea = styled.textarea`
  display: block;
  outline: none;
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  height: 100px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
`;

export const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
`;

export const SendButton = styled.button`
  cursor: pointer;
  margin: 0;
  margin-right: 1rem;
  background-color: var(--link-color);
  border: none;
  border-radius: 3px;
  color: var(--bg-color);
  padding: 0.75rem 1rem;
  transition: 0.2s;
  font-size: 1.15rem;

  @media (max-width: 480px) {
    font-size: 1rem;
  }

  &:hover {
    background-color: var(--link-hover-color);
    color: var(--bg-color);
  }
`;
