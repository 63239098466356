import React from "react";
import { FaRegHandshake } from "react-icons/fa";

import ContactForm from "./ContactForm";
import * as S from "./styles";

const Contact = () => (
  <>
    <S.Heading>
      <FaRegHandshake />
      Let's get in touch!
    </S.Heading>
    <p>
      I'd love to hear from you! If you have any thoughts on my posts, an
      exciting project idea, a technical question or you just want to have a
      conversation, please{" "}
      <a href="mailto:franco.dalessio12@gmail.com">
        <strong>send me an email </strong>
      </a>
      <strong>or use the following form. </strong>I will get back to you as soon
      as possible!
    </p>
    <ContactForm />
  </>
);

export default Contact;
