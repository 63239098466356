import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Contact from "../components/Contact";

const About = ({ location }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO title="About me" />
      <h1>About me</h1>
      <p>
        I'm Franco D'Alessio, a Front-end Web Developer based in{" "}
        <strong>Berlin, Germany</strong>. I have a Bachelor's degree in
        Software Engineering and I've been working <strong>full time</strong> as
        a <strong>web developer </strong>
        for <strong>more than 5 years</strong>. I have experience working both
        in small consulting businesses and large, international companies.
      </p>
      <p>
        I do my best to write <strong>clean, elegant and efficient code</strong>
        . I enjoy builiding and improving{" "}
        <strong>user-centered products</strong>, and working closely with
        Product Managers and designers. I always hope my work produces a{" "}
        <strong>real impact and make users lives easier</strong>. I'm not a
        designer but I enjoy thinking about UX problems and what the users might
        be struggling with when using a certain product.
      </p>
      <p>
        I have a strong interest in <strong>new technologies</strong> and I
        consider myself a <strong>fast learner</strong>. I usually write about
        <strong> Javascript, React</strong> and a lot more related to web
        development. I do it as a way to{" "}
        <strong>contribute to the community</strong>, but I also do it for me.
        To write an article, I have to make the effort to really understand
        concepts and techinical stuff, which has been very helpful to me.
      </p>
      <Contact />
    </Layout>
  );
};

About.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default About;
