import React from "react";

import * as S from "./styles";

const ContactForm = () => (
  <form
    name="contact"
    method="POST"
    netlify-honeypot="bot-field"
    data-netlify="true"
  >
    <input type="hidden" name="form-name" value="contact" />
    <input type="hidden" name="bot-field" />
    <S.Row>
      <S.Label>
        Name
        <S.TextInput type="text" name="name" placeholder="John" required />
      </S.Label>
      <S.Label>
        Email
        <S.TextInput
          type="email"
          name="email"
          placeholder="john@gmail.com"
          required
        />
      </S.Label>
    </S.Row>
    <S.Row>
      <S.Label>
        Message
        <S.TextArea
          type="textarea"
          name="message"
          placeholder="Your message"
          required
        />
      </S.Label>
    </S.Row>
    <S.Submit>
      <S.SendButton type="submit">Send</S.SendButton>
    </S.Submit>
  </form>
);

export default ContactForm;
