import styled from "styled-components";

export const Heading = styled.h2`
  border-top: 1px solid var(--border-color);
  padding-top: 3rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;
